<app-header></app-header>
<div id="main-content">
    <div class="container-fluid">
        <!-- Page header section  -->
        <div class="block-header">
            <div class="row clearfix">
                <div class="col-lg-4 col-md-12 col-sm-12">
                    <h1>{{textLabelUserForm}} User Form</h1>
                    <span>JustDo Add User,</span>
                </div>            
            </div>
        </div>
    </div>

    <div class="row clearfix">
        <div class="col-sm-12">
            <div class="card">
                <div class="body">
                    <form [formGroup]="formUser" (ngSubmit)="onSubmitUser()">
                        <div class="row clearfix">
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="header">
                                        <h2>Basic Information</h2>
                                    </div>
                                    <div class="body">
                                        <div class="row clearfix">
                                            <div class="col-md-3">
                                                <div class="form-group c_form_group">
                                                    <label>Last Name <span class="text-danger">*</span></label>
                                                    <input 
                                                        class="form-control" 
                                                        type="text" 
                                                        formControlName="lastNameUser"
                                                        [ngClass]="{ 'is-invalid': submitted && f['lastNameUser'].errors }"
                                                    />
                                                    <div *ngIf="submitted && f['lastNameUser'].errors" class="invalid-feedback">
                                                        <div *ngIf="f['lastNameUser'].errors['required']">Last Name is required</div>
                                                        <div *ngIf="f['lastNameUser'].errors['minlength']">
                                                            Last Name must be at least 8 characters
                                                        </div>
                                                        <div *ngIf="f['lastNameUser'].errors['maxlength']">
                                                            Last Name must not exceed 15 characters
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group c_form_group">
                                                    <label>First Name <span class="text-danger">*</span></label>
                                                    <input 
                                                        class="form-control" 
                                                        type="text" 
                                                        formControlName="firstNameUser"
                                                        [ngClass]="{ 'is-invalid': submitted && f['firstNameUser'].errors }"
                                                    />
                                                    <div *ngIf="submitted && f['firstNameUser'].errors" class="invalid-feedback">
                                                        <div *ngIf="f['firstNameUser'].errors['required']">First Name is required</div>
                                                        <div *ngIf="f['firstNameUser'].errors['minlength']">
                                                            First Name must be at least 8 characters
                                                        </div>
                                                        <div *ngIf="f['firstNameUser'].errors['maxlength']">
                                                            First Name must not exceed 15 characters
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group c_form_group">
                                                    <label>Email <span class="text-danger">*</span></label>
                                                    <input class="form-control" 
                                                        formControlName="emailUser"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted && f['emailUser'].errors }"
                                                    />
                                                    <div *ngIf="submitted && f['emailUser'].errors" class="invalid-feedback">
                                                        <div *ngIf="f['emailUser'].errors['required']">Email is required</div>
                                                        <div *ngIf="f['emailUser'].errors['emailUser']">Email is invalid</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row clearfix">
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="header">
                                        <h2>Registration Information</h2>
                                    </div>
                                    <div class="body">
                                        <div class="row clearfix">
                                            <div class="col-md-3">
                                                <div class="form-group c_form_group">
                                                    <label>Company <span class="text-danger">*</span></label>
                                                    <select formControlName="companyUser"
                                                        class="form-control show-tick"
                                                        [ngClass]="{ 'is-invalid select-is-invalid': submitted && f['companyUser'].errors }">
                                                        <option disabled selected (value)="0">-Select-</option>
                                                        <option [ngValue]="item.codigo" *ngFor="let item of companyList">{{item.nombre}}
                                                    </select>
                                                    <div *ngIf="submitted && f['companyUser'].errors" class="invalid-feedback">
                                                        <div *ngIf="f['companyUser'].errors['required']">Select Company is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-3">
                                                <div class="form-group c_form_group">
                                                    <label>Role <span class="text-danger">*</span></label>
                                                    <select formControlName="rolUser"
                                                        class="form-control show-tick"
                                                        [ngClass]="{ 'is-invalid select-is-invalid': submitted && f['rolUser'].errors }">
                                                        <option disabled selected (value)="0">-Select-</option>
                                                        <option [ngValue]="item.codigo" *ngFor="let item of roleList">{{item.nombre}}
                                                    </select>
                                                    <div *ngIf="submitted && f['rolUser'].errors" class="invalid-feedback">
                                                        <div *ngIf="f['rolUser'].errors['required']">Select Rol is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-3">
                                                <div class="form-group c_form_group">
                                                    <label>Password <span class="text-danger">*</span></label>
                                                    <input 
                                                        class="form-control" 
                                                        type="password" 
                                                        formControlName="passwordUser"
                                                        [ngClass]="{ 'is-invalid': submitted && f['passwordUser'].errors }"
                                                    />
                                                    <div *ngIf="submitted && f['passwordUser'].errors" class="invalid-feedback">
                                                        <div *ngIf="f['passwordUser'].errors['required']">Password is required</div>
                                                        <div *ngIf="f['passwordUser'].errors['minlength']">
                                                            Password must be at least 6 characters
                                                        </div>
                                                        <div *ngIf="f['passwordUser'].errors['maxlength']">
                                                            Username must not exceed 40 characters
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-3">
                                                <div class="form-group c_form_group">
                                                    <label>Confirm Password <span class="text-danger">*</span></label>
                                                    <input 
                                                        class="form-control" 
                                                        type="password" 
                                                        formControlName="confirmPasswordUser"
                                                        [ngClass]="{ 'is-invalid': submitted && f['confirmPasswordUser'].errors }"
                                                    />
                                                    <div *ngIf="submitted && f['confirmPasswordUser'].errors" class="invalid-feedback">
                                                        <div *ngIf="f['confirmPasswordUser'].errors['required']">
                                                            Confirm Password is required
                                                        </div>
                                                        <div *ngIf="f['confirmPasswordUser'].errors['matching']">
                                                            Confirm Password does not match
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                            
                                        </div>
                                        <div class="row clearfix">
                                            <div class="col-md-3">
                                                <div class="form-group c_form_group">
                                                    <label>Assigned source</label>
                                                    <select formControlName="sourceUser"
                                                        class="form-control">
                                                        <option disabled selected (value)="0">-Select-</option>
                                                        <option [ngValue]="item.codigo" *ngFor="let item of sourceList">{{item.nombre}}
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-3"></div>
                                            <div class="col-md-3"></div>
                                            <div class="col-md-3"></div>
                                            <div class="col-sm-12 modal-footer">
                                                <button type="submit" class="btn btn-outline-secondary ml-1" [routerLink]="[backRoute]">Cancel</button>
                                                <button type="submit" class="btn btn-primary">Accept</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </form>
                </div>
            </div>            
        </div>
    </div>
</div>