import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../core/core.module';
import { RouterModule, Routes } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxPaginationModule } from 'ngx-pagination';
import { UserAddComponent } from './components/user-add/user-add.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { UserComponent } from './user.component';

const routes: Routes = [
  {
    path: 'all-users',
    component: UserListComponent,
    data: { title: 'B.T.S. | AllUsers' }
  },
  {
    path: 'add-user',
    component: UserAddComponent,
    data: { title: 'B.T.S. | AddUser' }
  },
  {
    path: 'edit-user/:usercode',
    component: UserAddComponent,
    data: { title: 'B.T.S. | EditUser' }
  },
  {
    path: 'user-profile',
    component: UserProfileComponent,
    data: { title: 'B.T.S. | UsersProfile' }
  }
];

@NgModule({
  declarations: [
    UserComponent,
    UserAddComponent,
    UserListComponent,
    UserProfileComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forChild(routes),
    ModalModule.forRoot(),
    BsDatepickerModule,
    NgxPaginationModule
  ]
})
export class UserModule { }
