import { Component } from '@angular/core';

@Component({
  selector: 'app-users',
  templateUrl: './user.component.html',
  styleUrl: './user.component.scss'
})
export class UserComponent {

}
