<app-header></app-header>
<div id="main-content">
    <div class="container-fluid">
        <!-- Page header section  -->
        <div class="block-header">
            <div class="row clearfix">
                <div class="col-lg-4 col-md-12 col-sm-12">
                    <h1>All Users Panel</h1>
                    <span>JustDo Users List,</span>
                </div>
                <div class="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                    <div
                        class="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                        <div class="border-right pr-4 mr-4 mb-2 mb-xl-0 hidden-xs">
                            <p class="text-muted mb-1">Admins</p>
                            <h5 class="mb-0">{{ totalAdminUsers }}</h5>
                        </div>
                        <div class="border-right pr-4 mr-4 mb-2 mb-xl-0">
                            <p class="text-muted mb-1">Sellers</p>
                            <h5 class="mb-0">{{ totalSellersUsers }}</h5>
                        </div>
                        <!-- <div class="pr-4 mb-2 mb-xl-0">
                            <p class="text-muted mb-1">Others</p>
                            <h5 class="mb-0">{{ totalOtherUsers }}</h5>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="row clearfix">
            <div class="col-sm-12">
                <div class="card">
                    <div class="body">
                        <form [formGroup]="formFindUser" (ngSubmit)="onSubmitFind()">
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Search Users"
                                    formControlName="txtFind"
                                    [ngClass]="{ 'is-invalid': submittedFind && f['txtFind'].errors }" />
                                <div class="input-group-append">
                                    <button type="submit" class="input-group-text">
                                        <i class="icon-magnifier"></i>
                                    </button>
                                    <!-- <span class="input-group-text" id="search-mail"><i
                                            class="icon-magnifier"></i></span> -->
                                </div>
                                <div *ngIf="submittedFind && f['txtFind'].errors" class="invalid-feedback">
                                    <div *ngIf="f['txtFind'].errors['required']">Field is required</div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
        <div class="row clearfix" *ngIf="showLoading">
            <div class="col-md-12 social">
                <div class="card">
                    <div class="profile-header d-flex justify-content-between justify-content-center"
                        style="color: #666;">
                        <strong>Loading...</strong>
                        <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row clearfix" *ngIf="showNoResults">
            <div class="col-md-12 social">
                <div class="card">
                    <div class="profile-header d-flex justify-content-center theme-bg gradient">
                        No users were found according to the criteria, please try again.
                    </div>
                </div>
            </div>
        </div>
        <div class="row clearfix" *ngIf="showResults">
            <div class="col-lg-12">
                <div class="row clearfix">

                    <div class="col-lg-3 col-md-6"
                        *ngFor="let usr of arrayUsers | paginate: { itemsPerPage: 8, currentPage: page }">
                        <div class="card">
                            <div class="header">
                                <!-- <ul class="header-dropdown dropdown" dropdown>
                                    <li class="dropdown">
                                        <a href="javascript:void(0);" id="button-basic" dropdownToggle
                                            aria-controls="dropdown-basic" class="dropdown-toggle"
                                            data-toggle="dropdown" role="button" aria-haspopup="true"
                                            aria-expanded="false"></a>
                                        <ul class="dropdown-menu theme-bg gradient  dropdown-menu-right"
                                            id="dropdown-basic" *dropdownMenu role="menu"
                                            aria-labelledby="button-basic">
                                            <li><a href="javascript:void(0);">Profile Edit</a></li>
                                        </ul>
                                    </li>
                                </ul> -->
                            </div>
                            <div class="body text-center">
                                <div class="circle">
                                    <img class="rounded-circle" src="assets/images/{{getProfilePath(usr.foto)}}"
                                        alt="Profile Photo" *ngIf="getProfilePath(usr.foto).length != 0">
                                    <span *ngIf="getProfilePath(usr.foto).length == 0"
                                        class="rounded-circle empty-img theme-bg gradient">
                                        <h1>{{firstLetter(usr.nombres)}}</h1>
                                    </span>
                                </div>
                                <h6 class="mt-3 mb-0">{{usr.nombres}} {{usr.apellidos}}</h6>
                                <span>{{usr.email}}</span>
                                <ul class="mt-3 list-unstyled d-flex justify-content-center">
                                    <li><a class="p-3" [routerLink]="[editUserRoute,usr.codigo]"
                                            href="javascript:void(0)"><i class="fa fa-pencil"></i></a></li>
                                </ul>
                                <button class="btn btn-default btn-sm"
                                    (click)="openModalProfile(profile, usr)">Profile</button>
                                <button class="btn btn-default btn-sm ml-1"
                                    (click)="openModal(template, usr.codigo)">Delete</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="card">
                <div style="margin-top: 10px;">
                    <pagination-controls class="custom-pagination" (pageChange)="page = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Disable User Confirmation Modal -->
<ng-template #template>
    <div class="modal-body text-center">
        <p>Do you want disable this user?</p>
        <button type="button" class="btn btn-default" (click)="onDisableUser(codeUserForDelete)">Yes</button>
        <button type="button" class="btn btn-dark" (click)="decline()">No</button>
    </div>
</ng-template>

<!-- User Profile Modal -->
<ng-template #profile>
    <div class="modal-header">
        <h5 class="modal-title" id="defaultModalLabel">User Profile</h5>
    </div>
    <div class="modal-body">
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12">
                <div class="card profile-header">
                    <div class="body text-center">
                        <div class="circle">
                            <img *ngIf="getProfilePath(userSelected.foto).length != 0"
                                src="assets/images/{{getProfilePath(userSelected.foto)}}" class="rounded-circle" alt="">
                            <span *ngIf="getProfilePath(userSelected.foto).length == 0" class="rounded-circle empty-img"
                                [attr.style]="primaryGradientModal">
                                <h1>{{firstLetter(userSelected.nombres)}}</h1>
                            </span>
                        </div>
                        <div class="mt-3">
                            <h5 class="mb-0">{{userSelected.nombres}} <strong>{{userSelected.apellidos}}</strong>
                            </h5>
                            <span>{{userSelected.rol}}</span>
                        </div>
                        <div class="m-t-15">
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="header">
                        <h2>About Me</h2>
                    </div>
                    <div class="body">
                        <small class="text-muted">Email Address: </small>
                        <p>{{userSelected.email}}</p>
                        <small class="text-muted">Company: </small>
                        <p>{{userSelected.empresa}}</p>
                        <small *ngIf="userSelected.fuente.length > 0" class="text-muted">Assign Seller: </small>
                        <p *ngIf="userSelected.fuente.length > 0">{{userSelected.fuente}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
        <!-- <button type="button" class="btn btn-primary theme-bg gradient" style="background-color: #6cb440;">Add</button> -->
    </div>
</ng-template>