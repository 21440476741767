import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import Validation from '../../../../shared/utils/validation';
import { ActivatedRoute, Router } from "@angular/router";
import { UserModel } from '../../../../shared/models/user.model';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../../shared/services/user.service';
import { CatalogModel } from '../../../../shared/models/catalog.model';
import { retry } from 'rxjs/operators';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrl: './user-add.component.scss'
})
export class UserAddComponent {
  userdata: any;
  ud: any;
  userDataFinded!: UserModel;
  codeUser!: string;
  ccode: string = "";
  ucode: string = "";
  tokn: string = "";
  roleList!: CatalogModel[];  
  companyList!: CatalogModel[];
  sourceList!: CatalogModel[];
  rl:any;
  cm:any;
  so:any;
  textLabelUserForm!: string;
  backRoute: string = "";
  isEdit = false;

  formUser: FormGroup = new FormGroup({
    lastNameUser: new FormControl(''),
    firstNameUser: new FormControl(''),
    emailUser: new FormControl(''),
    userNameUser: new FormControl(''),
    passwordUser: new FormControl(''),
    confirmPasswordUser: new FormControl(''),
    rolUser: new FormControl(''),
    companyUser: new FormControl(''),
    sourceUser: new FormControl(''),
    /* acceptTerms: new FormControl(false), */
  });
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userservice: UserService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.ccode = this.activatedRoute.snapshot.params['ccode'];
    this.ucode = this.activatedRoute.snapshot.params['ccode'];
    this.tokn = sessionStorage.getItem('tkn') || (localStorage.getItem('tkn_' + this.ccode + '-' + this.ucode) || '');
    this.ud = sessionStorage.getItem('userdata') || (localStorage.getItem('userdata_' + this.ccode + '-' + this.ucode) || '');
    this.userdata = this.ud == '' ? '' : JSON.parse(this.ud);

    if (this.userdata == null || this.userdata == undefined) {
      this.router.navigate(['']);
      sessionStorage.clear();
    } else {
      console.log(this.activatedRoute.snapshot.params);
      this.codeUser = this.activatedRoute.snapshot.params['usercode'];
      this.backRoute = '/' + this.ccode + '/' + this.ucode + '/users/all-users';
      this.rl = sessionStorage.getItem('roleList')||(localStorage.getItem('roleList_' + this.ccode + '-' + this.ucode) || '');
      this.roleList = JSON.parse(this.rl);
      this.so = sessionStorage.getItem('sourceList')||(localStorage.getItem('sourceList_' + this.ccode + '-' + this.ucode) || '');
      this.sourceList = JSON.parse(this.so);
      this.cm = sessionStorage.getItem('companyList')||(localStorage.getItem('companyList_' + this.ccode + '-' + this.ucode) || '');
      this.companyList = JSON.parse(this.cm);
      if (this.codeUser != undefined) {
        console.log("parametro codigo " + this.codeUser + " se puede editar");
        this.textLabelUserForm = "Edit";
        this.isEdit = true;
        this.userservice.getUserById(this.tokn, this.codeUser)
          .pipe(retry(2))
          .subscribe(
            result => {
              this.userDataFinded = result;
              console.log(this.userDataFinded);
              this.formUser = this.formBuilder.group(
                {
                  lastNameUser: [
                    this.userDataFinded.apellidos,
                    [
                      Validators.required,
                      Validators.minLength(3),
                      Validators.maxLength(30),
                    ],
                  ],
                  firstNameUser: [
                    this.userDataFinded.nombres,
                    [
                      Validators.required,
                      Validators.minLength(3),
                      Validators.maxLength(30),
                    ],
                  ],
                  emailUser: [
                    this.userDataFinded.email,
                    [

                      Validators.required, Validators.email
                    ]
                  ],
                  passwordUser: [
                    '12345678',
                    [
                      Validators.required,
                      Validators.minLength(8),
                      Validators.maxLength(15),
                    ],
                  ],
                  confirmPasswordUser: [
                    '12345678',
                    Validators.required],
                  rolUser: [
                    this.userDataFinded.rolCodigo,
                    Validators.required],
                  companyUser: [
                    this.userDataFinded.empresaCodigo,
                    Validators.required],
                  sourceUser: [
                    this.userDataFinded.fuente
                  ],
                },
                {
                  validators: [Validation.match('passwordUser', 'confirmPasswordUser')],
                }
              );
            },
            error => {
              this.toastr.error(JSON.stringify(error));
            });

      } else {
        console.log("no se tiene codigo es para nuevo");
        this.textLabelUserForm = "Add";
        this.formUser = this.formBuilder.group(
          {
            lastNameUser: [
              '',
              [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(30),
              ],
            ],
            firstNameUser: [
              '',
              [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(30),
              ],
            ],
            emailUser: ['', [Validators.required, Validators.email]],
            passwordUser: [
              '',
              [
                Validators.required,
                Validators.minLength(8),
                Validators.maxLength(15),
              ],
            ],
            confirmPasswordUser: ['', Validators.required],
            rolUser: ['', Validators.required],
            companyUser: [
              this.userdata.empresaCodigo,
              Validators.required],
            sourceUser: [],
          },
          {
            validators: [Validation.match('passwordUser', 'confirmPasswordUser')],
          }
        );
      }
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formUser.controls;
  }

  // Prepare and Upadate Data Method
  onSubmitUser(): void {
    this.submitted = true;

    if (this.formUser.invalid) {
      return;
    }

    let userDataBeforeSend: UserModel;
    // Prepare object model before save
    userDataBeforeSend = {
      codigo: this.codeUser != undefined ? this.userDataFinded.codigo : "",
      nombres: this.formUser.value.firstNameUser,
      apellidos: this.formUser.value.lastNameUser,
      email: this.formUser.value.emailUser,
      rol: this.formUser.value.rolUser,
      empresa: this.formUser.value.companyUser,
      fuente: this.formUser.value.sourceUser,
      genero: "0",
      estado: 1,
      password: this.formUser.value.passwordUser,
      status: 1,
      foto: null,
      bookingList: null,
      empresaCodigo: this.formUser.value.companyUser,
      rolCodigo: this.formUser.value.rolUser
    }

    console.log(userDataBeforeSend);

    // Call service data
    if (this.isEdit) {
      this.userservice.updateUserById(this.tokn, userDataBeforeSend)
        .pipe(retry(2))
        .subscribe(
          () => {
            this.toastr.success('Successful registration', 'The data has been updated correctly!');
            // Redirect User List View Component
            this.router.navigate([this.backRoute]);
          },
          error => {
            this.toastr.error(JSON.stringify(error));
          });
    } else {
      this.userservice.createUser(this.tokn, userDataBeforeSend)
        .pipe(retry(2))
        .subscribe(
          result => {
            this.toastr.success('Successful registration', 'The user has been created correctly!');
            // Redirect User List View Component
            this.router.navigate([this.backRoute]);
          },
          error => {
            console.log(error);
            this.toastr.error(JSON.stringify(error));
          });
    }


  }

  // Reset fields form method
  onReset(): void {
    this.submitted = false;
    this.formUser.reset();
  }

  // Obtain Filename for avatar photo method
  getNamePhotoByEmail(emailValue: string, charValueSplit: string): string {
    const returnValue = emailValue.split(charValueSplit);
    return returnValue[0] + ".png";
  }

}
