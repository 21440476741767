import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { LoginService } from '../../../../shared/services/login.service';
import { UserService } from '../../../../shared/services/user.service';
import { retry } from 'rxjs/operators';
import { UserModel } from '../../../../shared/models/user.model';

import { GenderEnum } from '../../../../shared/enums/gender.enum';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { UtilImage } from '../../../../shared/utils/utilImage';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.scss'
})
export class UserListComponent {

  modalRef!: BsModalRef;
  message!: string;
  username = "";
  userdata: any;
  ud: any;
  ccode: string = "";
  ucode: string = "";
  tokn: string = "";
  totalAdminUsers: number = 0;
  totalSellersUsers: number = 0;
  totalOtherUsers: number = 0;
  userSelected!: UserModel;
  codeUserForDelete!: number;
  genderMaleName: string;
  editUserRoute: string = "";
  primaryGradientModal!: string;

  public arrayUsers: UserModel[] = [];
  public arrayUsersInitialTemp: UserModel[] = [];
  public arrayUsersTemp: UserModel[] = [];

  showModal!: boolean;
  showResults: boolean;
  showNoResults: boolean;
  showLoading: boolean;
  page: number = 1;


  formFindUser: FormGroup = new FormGroup({
    txtFind: new FormControl('')
  });

  submittedFind = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private loginService: LoginService,
    private userservice: UserService,
    private utilImage: UtilImage,
    private activatedRoute: ActivatedRoute
  ) {
    this.genderMaleName = GenderEnum.maleName;
    this.showResults = false;
    this.showNoResults = false;
    this.showLoading = false;
  }

  ngOnInit(): void {
    this.ccode = this.activatedRoute.snapshot.params['ccode'];
    this.ucode = this.activatedRoute.snapshot.params['ucode'];
    this.tokn = sessionStorage.getItem('tkn') || (localStorage.getItem('tkn_' + this.ccode + '_' + this.ucode) || '');
    this.ud = sessionStorage.getItem('userdata') || (localStorage.getItem('userdata_' + this.ccode + '_' + this.ucode) || '');
    this.primaryGradientModal = "background:" + (sessionStorage.getItem('modal-header-color')|| (localStorage.getItem('modal-header-color_' + this.ccode + '_' + this.ucode) || ''));
    // this.toastr.success('Hello world!', 'Toastr fun!');

    if (this.tokn == null) {
      this.router.navigate(['']);
    } else {
      //posteriormente será  un suscribe del servicio de adquisicioón de datos
      this.userdata = this.ud == '' ? '' : JSON.parse(this.ud);
      this.editUserRoute = '/' + this.ccode + '/' + this.ucode + '/users/edit-user/';

      // Call services
      this.getAllUsers();

      this.formFindUser = this.formBuilder.group(
        {
          txtFind: []
        }
      );

    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formFindUser.controls;
  }

  openModalProfile(template: TemplateRef<void>, dataUser: UserModel) {
    this.userSelected = dataUser;
    this.modalRef = this.modalService.show(template);
  }

  openModal(template: TemplateRef<void>, codeUser: string) {
    this.codeUserForDelete = Number.parseInt(codeUser);
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  confirm(): void {
    this.message = 'Confirmed!';
    this.modalRef?.hide();
  }

  decline(): void {
    this.message = 'Declined!';
    this.modalRef?.hide();
  }

  // List All User Method
  getAllUsers(): void {
    this.showLoading = true;

    this.userservice.getAllUsersService(this.tokn)
      .pipe(retry(2))
      .subscribe(
        result => {

          this.arrayUsers = result;

          if (this.arrayUsers.length > 0) {
            // Sort array by Last name
            this.arrayUsers.sort((p1, p2) => (p1.apellidos > p2.apellidos) ? 1 : (p1.apellidos < p2.apellidos) ? -1 : 0);
            console.log(this.arrayUsers);
            this.getTotalUserByRol(this.arrayUsers);

            // Save data in temporal array
            this.arrayUsersInitialTemp = this.arrayUsers;
            this.showResults = true;
            this.showLoading = false;
          } else {
            this.showNoResults = true;
            this.showLoading = false;
          }
        },
        error => {
          /* this.router.navigate(['']);
          sessionStorage.clear(); */
          this.toastr.error(error.message);
        });
  }

  // List All User Method
  findUserByNameEmail(txtFind: string): void {
    this.userservice.findUserByNameEmailService(this.tokn, txtFind)
      .pipe(retry(2))
      .subscribe(
        result => {

          this.arrayUsers = result;

          if (this.arrayUsers.length > 0) {
            // Sort array by Last name
            this.arrayUsers.sort((p1, p2) => (p1.apellidos > p2.apellidos) ? 1 : (p1.apellidos < p2.apellidos) ? -1 : 0);

            //console.log(this.arrayUsers);

            this.getTotalUserByRol(this.arrayUsers);

            this.showResults = true;
            this.showLoading = false;
            this.showNoResults = false;
          } else {
            this.totalAdminUsers = 0;
            this.totalSellersUsers = 0;
            this.showNoResults = true;
            this.showLoading = false;
            this.showResults = false;
          }
        },
        error => {
          /* this.router.navigate(['']);
          sessionStorage.clear(); */
          this.toastr.error(error.message);
        });

  }

  // Users Find Method
  onSubmitFind(): void {
    this.showLoading = true;
    this.showResults = false;
    this.showNoResults = false;
    this.submittedFind = true;

    if (this.formFindUser.value.txtFind != null && this.formFindUser.value.txtFind != "") {
      this.findUserByNameEmail(this.formFindUser.value.txtFind);
    } else {
      this.arrayUsers = this.arrayUsersInitialTemp;
      this.getTotalUserByRol(this.arrayUsers);
      this.showLoading = false;
      this.showResults = true;
      this.showNoResults = false;
    }
  }

  // Count users by role method
  getTotalUserByRol(arrayUsers: any) {
    this.arrayUsersTemp = arrayUsers.filter(function (usr: { rol: string; }) {
      return usr.rol === 'ADMINISTRATOR';
    });
    this.totalAdminUsers = this.arrayUsersTemp.length;

    this.arrayUsersTemp = arrayUsers.filter(function (usr: { rol: string; }) {
      return usr.rol === 'SELLER';
    });
    this.totalSellersUsers = this.arrayUsersTemp.length;
  }

  // Confirmation Message Method
  /* onDisableUser(): void {
    this.toastr.success('Successful disable', 'The user has been disabled correctly!');

    this.modalRef?.hide();
  } */

  onDisableUser(userCode: number): void {
    // Call User Disable service
    this.userservice.deleteUserById(this.tokn, userCode.toString())
      .pipe(retry(2))
      .subscribe(
        () => {
          this.toastr.success('Successful disablement', 'The data has been deleted correctly!');

          this.showLoading = true;
          this.showResults = false;
          this.showNoResults = false;

          this.getAllUsers();
          this.modalRef?.hide();
        },
        error => {
          this.toastr.error(JSON.stringify(error));
        });
  }

  // Get User Profile Path Method
  getProfilePath(photValue: string|null): string {
    return this.utilImage.getProfileImagePath(photValue);
  }

  firstLetter(name:string):string{
    return name.substring(0,1).toUpperCase();
  }

}
